import React, { useState, useEffect } from 'react';
import { addProduct, fetchCategory, fetchSizes, fetchTypes } from '../api/productApi';
import '../assets/styles/AddProductForm.css';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const AddProductForm = () => {
    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [sp, setSp] = useState('');
    const [cp, setCp] = useState('');
    const [stock, setStock] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState('');
    const [types, setTypes] = useState([]);
    const [size, setSize] = useState('');
    const [sizes, setSizes] = useState([]);
    const [brand, setBrand] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({});

    const userRole = localStorage.getItem('userRole');

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Product name is required';
        if (!barcode) newErrors.barcode = 'Barcode is required';
        if (!sp) newErrors.sp = 'sp is required';
        if (!cp) newErrors.cp = 'cp is required';
        if (!stock) newErrors.stock = 'Stock is required';
        if (!category) newErrors.category = 'Category is required';
        if (!type) newErrors.type = 'Type is required';
        if (!size) newErrors.size = 'Size is required';
        if (!brand) newErrors.brand = 'Brand is required';
        return newErrors;
    };

    // Fetch category and types on component mount

    useEffect(() => {
        const getCategory = async () => {
            try {
                const response = await fetchCategory(); // Fetch category from backend
                setCategories(response.categories); // Set the category in state
            } catch (error) {
                console.error("Error fetching category:", error.message);
            }
        };

        const getTypes = async () => {
            try {
                const response = await fetchTypes(); // Fetch types from backend
                setTypes(response.types); // Set the types in state
            } catch (error) {
                console.error("Error fetching types:", error.message);
            }
        };

        const getSizes = async () => {
            try {
                const response = await fetchSizes(); // Fetch sizes from backend
                setSizes(response.sizes); // Set the types in state
            } catch (error) {
                console.error("Error fetching sizes:", error.message);
            }
        };

        getCategory();
        getTypes();
        getSizes();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const product = { name, barcode, sp, cp, stock, category, type, size, brand };
        await addProduct(product);

        // Reset the form
        setName('');
        setBarcode('');
        setSp('');
        setCp('');
        setStock('');
        setCategory('');
        setType('');
        setSize('');
        setBrand('');
        setErrors({});
        setSuccessMessage('Product added successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
    };

    return (
        <form className="add-product-form" onSubmit={handleSubmit}>
            <h2>Add New Product</h2>
            {successMessage && <div className="success-message">{successMessage}</div>}

            <div className="form-group">
                <input type="text" placeholder="Product Name" value={name} onChange={(e) => setName(e.target.value)} required />
                {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className="form-group tooltip">
                <input type="text" placeholder="Barcode" value={barcode} onChange={(e) => setBarcode(e.target.value)} required />
                <AiOutlineInfoCircle className="info-icon" />
                <span className="tooltip-text">Enter a unique barcode for the product</span>
                {errors.barcode && <span className="error-message">{errors.barcode}</span>}
            </div>

            <div className='two'>
                <div className="form-group">
                    <input type="text" placeholder="Brand" value={brand} onChange={(e) => setBrand(e.target.value)} required />
                    {errors.brand && <span className="error-message">{errors.brand}</span>}
                </div>

                <div className="form-group">
                    <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                        <option value="" disabled>Category</option>
                        {categories.map((categoryOption) => (
                            <option key={categoryOption} value={categoryOption}>
                                {categoryOption.charAt(0).toUpperCase() + categoryOption.slice(1)}
                            </option>
                        ))}
                    </select>
                    {errors.category && <span className="error-message">{errors.category}</span>}
                </div>
            </div>

            <div className='two'>
                <div className="form-group">
                    <select value={type} onChange={(e) => setType(e.target.value)} required>
                        <option value="" disabled>Type</option>
                        {types.map((typeOption) => (
                            <option key={typeOption} value={typeOption}>
                                {typeOption.charAt(0).toUpperCase() + typeOption.slice(1)}
                            </option>
                        ))}
                    </select>
                    {errors.type && <span className="error-message">{errors.type}</span>}
                </div>

                <div className="form-group">
                    <select value={size} onChange={(e) => setSize(e.target.value)} required>
                        <option value="" disabled>Size</option>
                        {sizes.map((sizeOption) => (
                            <option key={sizeOption} value={sizeOption}>
                                {sizeOption.charAt(0).toUpperCase() + sizeOption.slice(1)}
                            </option>
                        ))}
                    </select>
                    {errors.size && <span className="error-message">{errors.size}</span>}
                </div>
            </div>

            <div className='two'>
                <div className="form-group">
                    <input type="number" placeholder="cp" value={cp} onChange={(e) => setCp(e.target.value)} required />
                    {errors.cp && <span className="error-message">{errors.cp}</span>}
                </div>

                <div className="form-group">
                    <input type="number" placeholder="sp" value={sp} onChange={(e) => setSp(e.target.value)} required />
                    {errors.sp && <span className="error-message">{errors.sp}</span>}
                </div>

                <div className="form-group">
                    <input type="number" placeholder="Stock" value={stock} onChange={(e) => setStock(e.target.value)} required />
                    {errors.stock && <span className="error-message">{errors.stock}</span>}
                </div>
            </div>
            <button type="submit">Add Product</button>
        </form>
    );
};

export default AddProductForm;
