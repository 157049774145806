import React, { useEffect, useState } from 'react';
import '../assets/styles/OrderHistory.css';
import { formatPrice } from '../utils/formatPrice';
import { formatDate } from '../utils/formatDate';
import { getAllOrders } from '../api/orderApi'; // Import your API function
import { getProductById } from '../api/productApi';

const OrderHistory = () => {
    const [orders, setOrders] = useState([]); // State to hold the fetched orders
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null); // State to manage error state
    const [message, setMessage] = useState(''); // State to manage success/error messages

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await getAllOrders(); // Fetch orders from API

                if (response) {
                    // Fetch product details for each product in the orders
                    const ordersWithProductDetails = await Promise.all(
                        response.map(async (order) => {
                            const productsWithDetails = await getOrderProductDetails(order);
                            return { ...order, products: productsWithDetails }; // Add product details to order
                        })
                    );
                    setOrders(ordersWithProductDetails); // Update orders state with product details
                } else {
                    throw new Error('Failed to fetch orders');
                }
            } catch (error) {
                console.error(error); // Log error for debugging
                setError('Failed to fetch orders.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Fetch product details for each productID in an order
    const fetchProductDetails = async (productID) => {
        try {
            const product = await getProductById(productID); // Fetch product data by ID
            return product; // Return the fetched product details
        } catch (error) {
            console.error('Error fetching product details:', error);
            return null; // Return null in case of error
        }
    };

    // Get product details for all products in an order
    const getOrderProductDetails = async (order) => {
        const productDetailsPromises = order.products.map(async (product) => {
            const productData = await fetchProductDetails(product.productID);
            return {
                ...product,
                ...productData, // Merge product data with fetched product details
            };
        });

        const detailedProducts = await Promise.all(productDetailsPromises);
        return detailedProducts;
    };

    if (loading) {
        return <p>Loading...</p>; // Loading state
    }

    if (error) {
        return <p>{error}</p>; // Error state
    }

    return (
        <div className="order-history">
            <h2>Order History</h2>
            {message && <div className="message">{message}</div>} {/* Conditionally render the message */}
            {orders.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Items</th>
                            <th>Total Price</th>
                            <th>Total Quantity</th>
                            <th>Order Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => {
                            const totalPrice = order.products.reduce((sum, product) => sum + (product.quantity * product.sp), 0);
                            const totalQuantity = order.products.reduce((sum, product) => sum + product.quantity, 0);

                            return (
                                <tr key={order._id}>
                                    <td>{index + 1}</td>
                                    <td className='product-info'>
                                        <ul>
                                            {order.products.map((product, i) => (
                                                <li key={product.productID}>
                                                    {product.name} - {product.brand} - {product.category} -
                                                    (x{product.quantity}) - <span className='size'>{product.size}</span> - {formatPrice(product.sp)}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td>{formatPrice(totalPrice)}</td>
                                    <td>{totalQuantity}</td>
                                    <td>{formatDate(order.currentTimeStamp)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No orders found.</p>
            )}
        </div>
    );
};

export default OrderHistory;
