import React, { useEffect, useState } from 'react';
import { getAllRecentProducts } from '../api/productApi';
import '../assets/styles/AddProductForm.css';
import { formatPrice } from '../utils/formatPrice.js';
import { formatDate } from '../utils/formatDate.js';

const NewProductAdded = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1); // Track current page
    const [rows, setRows] = useState(15); // Set default rows per page

    // Fetch recent products
    useEffect(() => {
        const fetchRecentProducts = async () => {
            try {
                const response = await getAllRecentProducts(); // Fetch recent products
                setProducts(response);
                setFilteredProducts(response); // Initially set all products
            } catch (error) {
                console.error("Failed to fetch recent products:", error);
            }
        };
        fetchRecentProducts();
    }, []);

    // Apply filters (category and search)
    useEffect(() => {
        let filtered = products;

        // Apply category filter if selected
        if (categoryFilter) {
            filtered = filtered.filter(product => product.category === categoryFilter);
        }

        // Apply search term filter if there is one
        if (searchTerm) {
            filtered = filtered.filter(product => 
                product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.barcode.includes(searchTerm)
            );
        }

        setFilteredProducts(filtered);
        setPage(1); // Reset to the first page whenever filters change
    }, [categoryFilter, searchTerm, products]);

    // Pagination logic
    const paginatedProducts = filteredProducts.slice((page - 1) * rows, page * rows);
    const totalPages = Math.ceil(filteredProducts.length / rows);

    // Handle category filter change
    const handleCategoryChange = (e) => setCategoryFilter(e.target.value);

    // Handle search term change
    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    // Pagination control handlers
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <div className="recent_added_products">
            <h2>Recently Added Products</h2>

            {/* Filters */}
            <div className="filters">
                <div className="category">
                    <label>Category</label>
                    <select value={categoryFilter} onChange={handleCategoryChange}>
                        <option value="">All Categories</option>
                        {Array.from(new Set(products.map(product => product.category)))
                            .map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                    </select>
                </div>
                <div className="search_product">
                    <label>Search Product</label>
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name, brand, or barcode"
                    />
                </div>
            </div>

            {paginatedProducts.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Sr. no</th>
                            <th>Name</th>
                            <th>Barcode</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Type</th>
                            <th>CP</th>
                            <th>SP</th>
                            <th>Stock</th>
                            <th>Created Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedProducts.map((product, index) => (
                            <tr key={product._id}>
                                <td>{(page - 1) * rows + index + 1}</td>
                                <td>{product.name}</td>
                                <td>{product.barcode}</td>
                                <td>{product.brand}</td>
                                <td>{product.category}</td>
                                <td>{product.type}</td>
                                <td>{formatPrice(product.cp)}</td>
                                <td>{formatPrice(product.sp)}</td>
                                <td>{product.stock}</td>
                                <td>{formatDate(product.createdAt)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No recent products found.</p>
            )}

            {/* Pagination Controls */}
            <div className="pagination">
                <div className="result-info">
                    Showing {paginatedProducts.length} of {filteredProducts.length} results
                </div>
                <div className="page-controls">
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(num => (
                        <button
                            key={num}
                            onClick={() => handlePageChange(num)}
                            className={page === num ? 'active' : ''}
                        >
                            {num}
                        </button>
                    ))}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewProductAdded;
