import React, { useState, useEffect } from 'react';
import noInternet from '../assets/images/no_internet.jpeg';

const NoInternetPage = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        // Listen for online and offline events
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Clean up event listeners when the component is unmounted
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (!isOnline) {
        // Show the No Internet page when offline
        return (
            <>
                <div style={styles.container}>
                    <h1>No Internet Connection</h1>
                    <img src={noInternet} style={styles.img} />
                    <p>Please check your internet connection and try again.</p>
                </div>
            </>
        );
    }

    // If the user is online, return null or your main app content
    return null;
};

const styles = {
    img: {
        height: '40vh',
        width: '20vw',
        margin: '1rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        textAlign: 'center',
    },
};

export default NoInternetPage;
