import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../api/userApi'; 
import '../assets/styles/ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await forgotPassword(email); // Calling the API function
      setMessage(response.message); // Display the success message
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h2>Forgot Password</h2>
        <p>Enter your email address to reset your password.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Updating the email state
              placeholder="Enter your email"
              required
            />
          </div>
          <button type="submit" className="reset-button">Reset Password</button>
        </form>
        {message && <p>{message}</p>}
        <div className="forgot-password-links">
          <p>
            Remembered your password? <Link to="/">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

