import React, { useEffect, useState } from 'react';
import { getAllProducts } from '../api/productApi';
import { formatPrice } from '../utils/formatPrice'; // Make sure this utility function is defined

const TopSellingProducts = () => {
    const [topSellingProducts, setTopSellingProducts] = useState([]);

    // Fetch products when component mounts
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const products = await getAllProducts();
                // Sort products by orderCount in descending order
                const sortedProducts = products.sort((a, b) => b.sales - a.sales);
                // Take the top 5 products, you can adjust this number as needed
                setTopSellingProducts(sortedProducts.slice(0, 5));
            } catch (error) {
                console.error("Failed to fetch products:", error);
            }
        };

        fetchProducts();
    }, []); // Empty dependency array to run only on mount

    return (
        <div className="top_selling_products">
            <h3>Top 5 Sales</h3>
            <ul>
                {topSellingProducts.length > 0 ? (
                    topSellingProducts.map((product) => (
                        <li key={product.barcode}>
                            {product.name} - {formatPrice(product.sp)} (Stock : {product.stock}, Orders : {product.orderCount}) <span>Total Sales : {formatPrice(product.sales)}</span>
                        </li>
                    ))
                ) : (
                    <p>No top-selling products available.</p>
                )}
            </ul>
        </div>
    );
};

export default TopSellingProducts;
