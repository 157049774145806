import React, { useState, useEffect } from 'react';
import { getProduct } from '../api/productApi';
import OrderSubmit from './OrderSubmit';
import BarcodeScanner from './BarcodeScanner';  // Import BarcodeScanner component
import '../assets/styles/PurchaseManager.css';
import { formatPrice } from '../utils/formatPrice';

const PurchaseManager = () => {
  const [barcode, setBarcode] = useState('');
  const [cart, setCart] = useState([]);
  const [orderSubmitted, setOrderSubmitted] = useState(false);

  // Load cart from localStorage on initial render
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }
  }, []);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cart));
    } else {
      localStorage.removeItem('cart');
    }
  }, [cart]);

  // Handle scanning barcode or manual input for adding product to cart
  const handleScan = async (scannedBarcode) => {
    setBarcode(scannedBarcode); // Set barcode value from scanner or manual input

    try {
      const product = await getProduct(scannedBarcode); // Fetch product by barcode
      if (product) {
        // Check if there's enough stock available
        const existingItem = cart.find(item => item.barcode === scannedBarcode);

        if (existingItem) {
          if (existingItem.quantity < product.stock) {
            setCart(prevCart => prevCart.map(item =>
              item.barcode === scannedBarcode ? { ...item, quantity: item.quantity + 1 } : item
            ));
          } else {
            alert('Not enough stock!');
          }
        } else if (product.stock > 0) {
          setCart(prevCart => [...prevCart, { ...product, quantity: 1 }]);
        } else {
          alert('Not enough stock!');
        }
      } else {
        alert("Product not found!");
      }
    } catch (error) {
      console.error("Failed to add product:", error);
    }
  };

  // Handle manual barcode input and add item to cart
  const handleAddToCart = async () => {
    if (barcode) {
      await handleScan(barcode);  // Use handleScan for both manual input and scanner
      setBarcode('');  // Reset input field
    }
  };

  const handleRemoveFromCart = (barcode) => {
    const updatedCart = cart.filter(item => item.barcode !== barcode);
    setCart(updatedCart);
  };

  const updateQuantity = (barcode, delta) => {
    setCart(cart.map(item => {
      if (item.barcode === barcode) {
        const newQuantity = item.quantity + delta;
        if (newQuantity > 0 && newQuantity <= item.stock) {
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    }));
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.sp * item.quantity, 0);
  };

  const calculateTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const handleOrderSubmit = () => {
    setOrderSubmitted(true);
    cart.forEach(item => {
      // Handle order submission logic
    });
  };

  const totalPrice = calculateTotalPrice();
  const totalQuantity = calculateTotalQuantity();

  return (
    <div className="purchase-manager">
      <h2>Purchase Manager</h2>

      {/* Barcode Scanner Component */}
      <BarcodeScanner onScan={handleScan} />

      {/* Manual input for barcode */}
      <div className="input-container">
        <input
          type="text"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          placeholder="Enter product barcode or scan"
        />
        <button onClick={handleAddToCart}>Add Item</button>
      </div>

      <div className="cart">
        <h3>Products</h3>
        {cart.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Brand</th>
                <th>Barcode</th>
                <th>Category</th>
                <th>Type</th>
                <th>Size</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <tr key={item.barcode}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.brand}</td>
                  <td>{item.barcode}</td>
                  <td>{item.category}</td>
                  <td>{item.type}</td>
                  <td className='size'>{item.size}</td>
                  <td>{formatPrice(item.sp)}</td>
                  <td>
                    <button onClick={() => updateQuantity(item.barcode, -1)}>-</button>
                    {item.quantity}
                    <button onClick={() => updateQuantity(item.barcode, 1)}>+</button>
                  </td>
                  <td>{formatPrice(item.sp * item.quantity)}</td>
                  <td>
                    <button onClick={() => handleRemoveFromCart(item.barcode)} className='remove'>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className='no-items'>No items in the cart.</p>
        )}

        <div className='price_details'>
          <h4>Total Quantity : {totalQuantity}</h4>
          <h4>Total Price : {formatPrice(totalPrice)}</h4>
        </div>

        <OrderSubmit
          handleOrderSubmit={handleOrderSubmit}
          cart={cart}
          totalPrice={totalPrice}
          totalQuantity={totalQuantity}
          setCart={setCart}
          orderSubmitted={orderSubmitted}
        />
      </div>
    </div>
  );
};

export default PurchaseManager;
