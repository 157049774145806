import React, { useEffect, useState } from 'react';
import '../assets/styles/AllOrders.css';
import { getAllOrders, deleteOrder } from '../api/orderApi'; // Adjust the import based on your file structure
import { formatPrice } from '../utils/formatPrice';
import { formatDate } from '../utils/formatDate';
import { getProductById } from '../api/productApi'; // Import the getProductById API call

const AllOrders = () => {
    const [orders, setOrders] = useState([]); // State to hold the fetched orders
    const [filteredOrders, setFilteredOrders] = useState([]); // State for orders after filtering
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null); // State to manage error state
    const [message, setMessage] = useState(''); // State to manage success/error messages
    const [searchTerm, setSearchTerm] = useState(''); // State to manage search term
    const [ordersPerPage, setOrdersPerPage] = useState(25); // Default orders per page
    const [currentPage, setCurrentPage] = useState(1); // State to manage the current page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await getAllOrders();
                if (response) {
                    // Once orders are fetched, fetch product details for each product
                    const ordersWithProductDetails = await Promise.all(
                        response.map(async (order) => {
                            const productsWithDetails = await getOrderProductDetails(order);
                            return { ...order, products: productsWithDetails };
                        })
                    );
                    setOrders(ordersWithProductDetails); // Update orders state with product details
                    setFilteredOrders(ordersWithProductDetails); // Initially set filtered orders to all orders
                    setTotalPages(Math.ceil(ordersWithProductDetails.length / ordersPerPage)); // Set total pages based on the number of orders
                } else {
                    throw new Error('Failed to fetch orders');
                }
            } catch (error) {
                console.error(error);
                setError('Failed to fetch orders.');
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [ordersPerPage]);

    // Fetch product details for each productID in an order
    const fetchProductDetails = async (productID) => {
        try {
            const product = await getProductById(productID);
            return product; // Return the fetched product details
        } catch (error) {
            console.error('Error fetching product details:', error);
            return null; // Return null in case of error
        }
    };

    // Get product details for all products in an order
    const getOrderProductDetails = async (order) => {
        const productDetailsPromises = order.products.map(async (product) => {
            const productData = await fetchProductDetails(product.productID);
            return {
                ...product,
                ...productData, // Merge product data with fetched product details
            };
        });

        const detailedProducts = await Promise.all(productDetailsPromises);
        return detailedProducts;
    };

    // Handle the delete order action
    const handleDeleteOrder = async (orderId) => {
        try {
            const response = await deleteOrder(orderId); // Only pass orderId
            if (response.success) {
                setOrders(orders.filter(order => order._id !== orderId)); // Remove the deleted order
                setMessage('Order deleted successfully');
                setTimeout(() => setMessage(''), 2000); // Clear the message after 2 seconds
            } else {
                setMessage('Failed to delete the order');
                setTimeout(() => setMessage(''), 2000);
            }
        } catch (error) {
            console.error('Error deleting order:', error);
            setMessage('An error occurred while deleting the order');
            setTimeout(() => setMessage(''), 2000);
        }
    };

    // Handle search term change and filter orders based on product name
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter orders based on search term
    useEffect(() => {
        const filtered = orders.filter((order) => {
            return order.products.some((product) =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setFilteredOrders(filtered);
        setTotalPages(Math.ceil(filtered.length / ordersPerPage)); // Recalculate the number of pages after filtering
    }, [searchTerm, orders, ordersPerPage]);

    // Pagination logic
    const startIndex = (currentPage - 1) * ordersPerPage;
    const paginatedOrders = filteredOrders.slice(startIndex, startIndex + ordersPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle orders per page change
    const handleOrdersPerPageChange = (e) => {
        setOrdersPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    // Generate pagination range with ellipses for large page sets
    const getPaginationRange = () => {
        const paginationRange = [];
        if (totalPages <= 10) {
            // Show all pages if there are 10 or fewer
            for (let i = 1; i <= totalPages; i++) {
                paginationRange.push(i);
            }
        } else {
            // When we are on a page that is near the end, show the current page before the last 3 pages
            if (currentPage > totalPages - 5) {
                paginationRange.push(1);
                paginationRange.push('...');
                for (let i = totalPages - 9; i <= totalPages; i++) {
                    paginationRange.push(i);
                }
            } else {
                // Show first 5 pages, then ellipsis, then last 3 pages
                for (let i = 1; i <= 10; i++) {
                    paginationRange.push(i);
                }
                paginationRange.push('...');
                for (let i = totalPages - 2; i <= totalPages; i++) {
                    paginationRange.push(i);
                }
            }
        }
        return paginationRange;
    };

    if (loading) {
        return <p>Loading...</p>; // Loading state
    }

    if (error) {
        return <p>{error}</p>; // Error state
    }

    return (
        <div className="all-orders">
            <h2>All Orders</h2>

            <div className='filters'>
                {/* Orders per page dropdown */}
                <div className="rows">
                    <label>Show</label>
                    <select value={ordersPerPage} onChange={handleOrdersPerPageChange}>
                        <option value={25}>25 orders</option>
                        <option value={50}>50 orders</option>
                        <option value={75}>75 orders</option>
                    </select>
                </div>

                {/* Search Filter */}
                <div className="search-order">
                    <label>Search by Product Name</label>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by product name"
                    />
                </div>
            </div>

            {message && <div className="message">{message}</div>} {/* Conditionally render the message */}

            {paginatedOrders.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Items</th>
                            <th>Total Price</th>
                            <th>Total Quantity</th>
                            <th>Order Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedOrders.map((order, index) => {
                            const totalPrice = order.products.reduce((sum, product) => sum + (product.quantity * product.sp), 0);
                            const totalQuantity = order.products.reduce((sum, product) => sum + product.quantity, 0);

                            return (
                                <tr key={order._id}>
                                    <td>{startIndex + index + 1}</td>
                                    <td className="product-info">
                                        <ul>
                                            {order.products.map((product, i) => (
                                                <li key={product.productID}>
                                                    {product.name} - {product.category} - {product.type} -
                                                    (x{product.quantity}) - <span className="size">{product.size}</span> - {formatPrice(product.sp)}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td>{formatPrice(totalPrice)}</td>
                                    <td>{totalQuantity}</td>
                                    <td>{formatDate(order.currentTimeStamp)}</td>
                                    <td>
                                        <button className="delete" onClick={() => handleDeleteOrder(order._id)}>
                                            Cancel Order
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No orders found.</p>
            )}

            {/* Pagination Controls */}
            <div className="pagination">
                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                )}
                {getPaginationRange().map((page, index) => (
                    <button
                        key={index}
                        className={currentPage === page ? 'active' : ''}
                        onClick={() => page !== '...' && handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                )}
            </div>
        </div>
    );
};

export default AllOrders;
