import React from 'react';
import '../assets/styles/Reports.css'
import download from '../assets/images/download.png'

// Utility function to download the report as CSV
const downloadCSV = (data, reportType) => {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Utility function to escape commas, newlines, and quotes in CSV content
    const escapeCSVValue = (value) => {
        if (typeof value === 'string') {
            // Escape double quotes and wrap text in double quotes
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    };

    // Prepare CSV for different report types
    if (reportType === 'sales') {
        csvContent += "Product Name,Total Sales,Total Quantity\n";
        data.salesDetails.forEach((item) => {
            // Ensure each field is properly escaped
            const productName = escapeCSVValue(item.productName);
            const totalSales = escapeCSVValue(item.totalSales);
            const totalQuantity = escapeCSVValue(item.totalQuantity);

            csvContent += `${productName},${totalSales},${totalQuantity}\n`;
        });
    } else if (reportType === 'stock') {
        csvContent += "Product Name,Stock,Sales,Order Count\n";
        data.stockData.forEach((item) => {
            // Ensure each field is properly escaped
            const productName = escapeCSVValue(item.productName);
            const stock = escapeCSVValue(item.stock);
            const sales = escapeCSVValue(item.sales);
            const orderCount = escapeCSVValue(item.orderCount);

            csvContent += `${productName},${stock},${sales},${orderCount}\n`;
        });
    } else if (reportType === 'profitLoss') {
        csvContent += "Total Revenue,Total Cost,Profit/Loss\n";
        // Escape values for proper CSV formatting
        csvContent += `${escapeCSVValue(data.totalRevenue)},${escapeCSVValue(data.totalCost)},${escapeCSVValue(data.profitLoss)}\n`;
    }

    // Create a downloadable link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportType}_report.csv`);
    document.body.appendChild(link); // Required for Firefox
    link.click();
};


// DownloadCSV component
const DownloadCSV = ({ data, reportType }) => {
    return (
        <button onClick={() => downloadCSV(data, reportType)} className='download-csv'>
            <img src={download} className='download' />
            Download CSV
        </button>
    );
};

export default DownloadCSV;
