import axios from 'axios';

const API_URL = 'https://api.nbeer.shop/orders';

// Utility function to handle errors and responses
const handleResponse = async (request) => {
    try {
        const response = await request;
        return response.data;
    } catch (error) {
        // Customize this according to how you'd like to handle errors
        console.error('API error:', error.response ? error.response.data : error.message);
        throw error.response ? error.response.data : error.message; // Throw error for further handling in component
    }
};

// Fetch all Orders
export const getAllOrders = async () => {
    return await handleResponse(axios.get(API_URL));
};

// Add a new Order
export const createOrder = async (order) => {
    return await handleResponse(axios.post(`${API_URL}/create`, order));
};

// Update Order by ID
export const updateOrder = async (id, updatedData) => {
    return await handleResponse(axios.put(`${API_URL}/update/${id}`, updatedData));
};

// Delete an Order by ID (no barcode required)
export const deleteOrder = async (orderId) => {
    return await handleResponse(axios.delete(`${API_URL}/del/${orderId}`)); // Only pass orderId
};

// Get Order by ID
export const getOrder = async (id) => {
    return await handleResponse(axios.get(`${API_URL}/get/${id}`));
};

// Get total sales for orders
export const getTotalSales = async () => {
    return await handleResponse(axios.get(`${API_URL}/sales`));
};

// Get total number of orders by quantity
export const getTotalOrdersByQuantity = async () => {
    return await handleResponse(axios.get(`${API_URL}/totalOrders`));
};

// Get Today's Orders
export const getTotalOrdersToday = async () => {
    return await handleResponse(axios.get(`${API_URL}/today`));
};

// Get Yesterday's Orders
export const getTotalOrdersYesterday = async () => {
    return await handleResponse(axios.get(`${API_URL}/yesterday`));
};

// Get Last Week's Orders
export const getTotalOrdersLastWeek = async () => {
    return await handleResponse(axios.get(`${API_URL}/lastweek`));
};

// Get Last Month's Orders
export const getTotalOrdersLastMonth = async () => {
    return await handleResponse(axios.get(`${API_URL}/lastmonth`));
};

// Get Last Year's Orders
export const getTotalOrdersLastYear = async () => {
    return await handleResponse(axios.get(`${API_URL}/lastyear`));
};

// Get Orders by specific date (yyyy-mm-dd)
export const getTotalOrdersByDate = async (date) => {
    return await handleResponse(axios.get(`${API_URL}/${date}`));
};
