import React, { useEffect, useState } from 'react';
import { getLowStockProducts, updateProduct, deleteProduct } from '../api/productApi'; // Make sure to import the API function
import { MdEdit, MdDelete } from "react-icons/md";
import '../assets/styles/LowStockProducts.css';
import EditProduct from './EditProduct';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { formatPrice } from '../utils/formatPrice';

const LowStockProducts = () => {
    const [lowStockProducts, setLowStockProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedBarcode, setSelectedBarcode] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1); // Track current page
    const [rows, setRows] = useState(15); // Set default rows per page
    const userRole = localStorage.getItem('userRole');

    // Fetch low stock products directly from API
    useEffect(() => {
        const fetchLowStockProducts = async () => {
            try {
                const products = await getLowStockProducts();  // Call API to get low stock products
                setLowStockProducts(products);  // Set the fetched low stock products
                setFilteredProducts(products);  // Initially set all products
            } catch (error) {
                console.error("Failed to fetch low stock products:", error);
            }
        };
        fetchLowStockProducts();
    }, []);

    // Apply category and search filters
    useEffect(() => {
        let filtered = lowStockProducts;

        // Apply category filter if selected
        if (categoryFilter) {
            filtered = filtered.filter(product => product.category === categoryFilter);
        }

        // Apply search term filter if there is one
        if (searchTerm) {
            filtered = filtered.filter(product => 
                product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.barcode.includes(searchTerm)
            );
        }

        // Update the state with the filtered products
        setFilteredProducts(filtered);
        setPage(1); // Reset to the first page whenever filters change
    }, [categoryFilter, searchTerm, lowStockProducts]);

    // Pagination logic
    const paginatedProducts = filteredProducts.slice((page - 1) * rows, page * rows);
    const totalPages = Math.ceil(filteredProducts.length / rows);

    // Handle category filter change
    const handleCategoryChange = (e) => setCategoryFilter(e.target.value);

    // Handle search term change
    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    // Edit handler
    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setIsEditModalOpen(true);
    };

    const handleModalClose = () => {
        setIsEditModalOpen(false);
        setSelectedProduct(null);
    };

    const handleProductUpdate = async (updatedProduct) => {
        try {
            await updateProduct(updatedProduct.barcode, updatedProduct);
            setLowStockProducts((prevProducts) =>
                prevProducts.map(product =>
                    product.barcode === updatedProduct.barcode ? updatedProduct : product
                )
            );
            handleModalClose();
        } catch (error) {
            console.error("Failed to update product:", error);
        }
    };

    // Delete handlers
    const handleDeleteClick = (product) => {
        setSelectedBarcode(product.barcode); // Set the barcode for deletion
        setSelectedProduct(product); // Set the selected product for modal display
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteProduct(selectedBarcode);
            setLowStockProducts(prevProducts =>
                prevProducts.filter(product => product.barcode !== selectedBarcode)
            );
            setIsDeleteModalOpen(false);
            setSelectedBarcode('');
            setSelectedProduct(null); // Clear selected product
        } catch (error) {
            console.error("Failed to delete product:", error);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setSelectedBarcode('');
        setSelectedProduct(null); // Clear selected product
    };

    // Pagination control handlers
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <div className="low-stock-products">
            <h2>Low Stock Products</h2>

            {/* Filters */}
            <div className="filters">
                <div className="category">
                    <label>Category</label>
                    <select value={categoryFilter} onChange={handleCategoryChange}>
                        <option value="">All Categories</option>
                        {Array.from(new Set(lowStockProducts.map(product => product.category)))
                            .map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                    </select>
                </div>
                <div className="search_product">
                    <label>Search Product</label>
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name, brand, or barcode"
                    />
                </div>
            </div>

            {paginatedProducts.length > 0 ? (
                <table className="low-stock-table">
                    <thead>
                        <tr>
                            <th>Sr no.</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Size</th>
                            <th>Type</th>
                            <th>Brand</th>
                            <th>Price</th>
                            <th>Stock</th>
                            {userRole === 'admin' && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedProducts.map((product, index) => (
                            <tr key={product.barcode}>
                                <td>{(page - 1) * rows + index + 1}</td>
                                <td>{product.name}</td>
                                <td>{product.category}</td>
                                <td className='size'>{product.size}</td>
                                <td>{product.type}</td>
                                <td>{product.brand}</td>
                                <td>{formatPrice(product.sp)}</td>
                                <td>{product.stock}</td>
                                {userRole === 'admin' && (
                                    <td className="actions">
                                        <button onClick={() => handleEditClick(product)} className="edit">
                                            <MdEdit />
                                        </button>
                                        <button onClick={() => handleDeleteClick(product)} className="delete">
                                            <MdDelete />
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No products with low stock.</p>
            )}

            {/* Edit Modal */}
            {isEditModalOpen && (
                <EditProduct
                    isOpen={isEditModalOpen}
                    onClose={handleModalClose}
                    product={selectedProduct}
                    onSave={handleProductUpdate}
                />
            )}

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onDelete={handleDeleteConfirm}
                productName={selectedProduct ? selectedProduct.name : 'this product'}
            />

            {/* Pagination Controls */}
            <div className="pagination">
                <div className="result-info">
                    Showing {paginatedProducts.length} of {filteredProducts.length} results
                </div>
                <div className="page-controls">
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(num => (
                        <button
                            key={num}
                            onClick={() => handlePageChange(num)}
                            className={page === num ? 'active' : ''}
                        >
                            {num}
                        </button>
                    ))}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LowStockProducts;
