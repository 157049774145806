import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    getTotalOrdersToday,
    getTotalOrdersYesterday,
    getTotalOrdersLastWeek,
    getTotalOrdersLastMonth,
    getTotalOrdersLastYear,
    getTotalOrdersByDate,
} from '../api/orderApi';
import { formatPrice } from '../utils/formatPrice';
import '../assets/styles/FilteredOrders.css';

const FilteredOrders = () => {
    const { filter } = useParams(); 
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [customDate, setCustomDate] = useState('');
    const [filterLabel, setFilterLabel] = useState('');
    const [currentPage, setCurrentPage] = useState(1); 
    const [totalSales, setTotalSales] = useState(0); 
    const [productCounts, setProductCounts] = useState([]);

    const getFilterLabel = () => {
        switch (filter) {
            case 'today':
                return 'Today\'s Orders';
            case 'yesterday':
                return 'Yesterday\'s Orders';
            case 'lastWeek':
                return 'Last Week\'s Orders';
            case 'lastMonth':
                return 'Last Month\'s Orders';
            case 'lastYear':
                return 'Last Year\'s Orders';
            case 'custom':
                return customDate ? `Orders for ${customDate}` : 'Custom Date Filter';
            default:
                return filter ? `Orders for ${filter}` : 'All Orders';
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                let response;
                switch (filter) {
                    case 'today':
                        response = await getTotalOrdersToday();
                        break;
                    case 'yesterday':
                        response = await getTotalOrdersYesterday();
                        break;
                    case 'lastWeek':
                        response = await getTotalOrdersLastWeek();
                        break;
                    case 'lastMonth':
                        response = await getTotalOrdersLastMonth();
                        break;
                    case 'lastYear':
                        response = await getTotalOrdersLastYear();
                        break;
                    case 'custom':
                        response = await getTotalOrdersByDate(customDate);
                        break;
                    default:
                        response = await getTotalOrdersByDate(filter); 
                }

                if (response && response.orders) {
                    setOrders(response.orders);
                    setTotalSales(response.totalSales);
                    setProductCounts(response.productCounts);
                } else {
                    setOrders([]);
                    setTotalSales(0);
                    setProductCounts([]);
                }

                setFilterLabel(getFilterLabel());

            } catch (error) {
                console.error("Error fetching filtered orders:", error);
            }
        };

        fetchOrders();
    }, [filter, customDate, currentPage]); // Include currentPage as a dependency

    const handleCustomDateChange = (e) => {
        setCustomDate(e.target.value);
    };

    const handleFilterByDate = async () => {
        if (customDate) {
            try {
                const response = await getTotalOrdersByDate(customDate);
                if (response && response.orders) {
                    setOrders(response.orders);
                    setTotalSales(response.totalSales);
                    setProductCounts(response.productCounts);
                } else {
                    setOrders([]);
                    setTotalSales(0);
                    setProductCounts([]);
                }
            } catch (error) {
                console.error("Error fetching orders by custom date:", error);
            }
        }
    };

    const handleFilterChange = (newFilter) => {
        if (newFilter === 'custom') {
            navigate(`/filtered-orders/custom`);
        } else {
            navigate(`/filtered-orders/${newFilter}`);
        }
    };

    return (
        <div className="filtered-orders">
            <h2>Filtered Orders</h2>

            <div className="filter-indicator">
                <p>Viewing : {filterLabel}</p>
                <p>Total Sales : {formatPrice(totalSales)}</p>
                <p>Total Products Ordered : {productCounts.reduce((acc, product) => acc + product.quantityOrdered, 0)}</p>
            </div>

            <div className='filters'>
                <div className="filter-control flex">
                    <label>Filter by</label>
                    <select
                        value={filter}
                        onChange={(e) => handleFilterChange(e.target.value)}
                    >
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="lastYear">Last Year</option>
                        <option value="custom">Custom Date</option>
                    </select>
                </div>

                <div className='flex'>
                    <label>Filter by Date</label>
                    <div className='custom'>
                        <input
                            type="date"
                            value={customDate}
                            onChange={handleCustomDateChange}
                            required
                        />
                        <button onClick={handleFilterByDate}>Filter by Date</button>
                    </div>
                </div>
            </div>

            <div className="product-breakdown">
                <h3>Product Breakdown</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Sr no.</th>
                            <th>Product Name</th>
                            <th>Category</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Price</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productCounts.length > 0 ? (
                            productCounts.map((product, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{product.name}</td>
                                    <td>{product.category}</td>
                                    <td>{product.type}</td>
                                    <td className='size'>{product.size}</td>
                                    <td>{formatPrice(product.price)}</td>
                                    <td>{product.quantityOrdered}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No product data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FilteredOrders;
