import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { resetPassword } from '../api/userApi';  // Import the API call function
import '../assets/styles/ResetPassword.css';

const ResetPassword = () => {
  const { token } = useParams();  // Extract the token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      // Call the resetPassword API function with token and new password
      const response = await resetPassword(token, newPassword);
      setMessage(response.message); // Show success or error message
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-box">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type={showPassword ? 'text' : 'password'} 
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} // Update the new password state
              placeholder="Enter new password"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type={showPassword ? 'text' : 'password'} 
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} // Update the confirm password state
              placeholder="Confirm new password"
              required
            />
          </div>
          <p className='show_password'>
            <input
              type='checkbox'
              id="check"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label htmlFor='check'>Show Password</label>
          </p>
          <button type="submit" className="reset-button">Reset Password</button>
        </form>
        {message && <p>{message}</p>} {/* Display any response message */}
        <div className="forgot-password-links">
          <p>
            Remembered your password? <Link to="/">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
