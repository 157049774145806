import React, { useState } from 'react';
import { getReport } from '../api/reportApi.js'; 
import '../assets/styles/Reports.css'; 
import DownloadCSV from '../utils/DownloadCSV.js'; 
import { formatPrice } from '../utils/formatPrice.js';

const Reports = () => {
    const [reportType, setReportType] = useState('sales');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [formError, setFormError] = useState('');

    const handleGenerateReport = async () => {
        if (!reportType || !startDate || !endDate) {
            setFormError('You have not selected a report type or date range.');
            return;
        }

        setFormError('');
        setLoading(true);
        setError('');
        setReportData(null);

        try {
            const data = await getReport(reportType, startDate, endDate);
            setReportData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const renderSalesReport = (data) => {
        if (!data || !data.salesDetails) {
            return <div>No sales data available.</div>;
        }
        return (
            <div className="report-card">
                <h3>Sales Report</h3>
                <p><strong>Total Sales :</strong> {formatPrice(data.totalSales)}</p>
                <p><strong>Total Quantity Sold :</strong> {data.totalQuantity}</p>
                <h4>Sales Details :</h4>
                <DownloadCSV data={data} reportType="sales" /> {/* Pass data and type */}
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Total Sales</th>
                            <th>Total Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.salesDetails.map((item, index) => (
                            <tr key={index}>
                                <td>{item.productName}</td>
                                <td>{formatPrice(item.totalSales)}</td>
                                <td>{item.totalQuantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderStockReport = (data) => {
        if (!data || !data.stockData) {
            return <div>No stock data available.</div>;
        }
        return (
            <div className="report-card">
                <h3>Stock Levels Report</h3>
                <DownloadCSV data={data} reportType="stock" /> {/* Pass data and type */}
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Stock</th>
                            <th>Sales</th>
                            <th>Order Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.stockData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.productName}</td>
                                <td>{item.stock}</td>
                                <td>{formatPrice(item.sales)}</td>
                                <td>{item.orderCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderProfitLossReport = (data) => {
        if (!data) {
            return <div>No profit/loss data available.</div>;
        }
        return (
            <div className="report-card">
                <DownloadCSV data={data} reportType="profitLoss" /> {/* Pass data and type */}
                <h3>Profit/Loss Report</h3>
                <p><strong>Total Revenue :</strong> {formatPrice(data.totalRevenue)}</p>
                <p><strong>Profit/Loss :</strong> {formatPrice(data.profitLoss)}</p>
            </div>
        );
    };

    const renderReport = () => {
        if (!reportData) return null;
        switch (reportType) {
            case 'sales':
                return renderSalesReport(reportData);
            case 'stock':
                return renderStockReport(reportData);
            case 'profitLoss':
                return renderProfitLossReport(reportData);
            default:
                return <div>No data available.</div>;
        }
    };

    return (
        <div className="reports">
            <h2>Generate Reports</h2>
            <div className="report-type-selector">
                <label>
                    <input
                        type="radio"
                        value="sales"
                        checked={reportType === 'sales'}
                        onChange={(e) => setReportType(e.target.value)}
                    />
                    Sales
                </label>
                <label>
                    <input
                        type="radio"
                        value="stock"
                        checked={reportType === 'stock'}
                        onChange={(e) => setReportType(e.target.value)}
                    />
                    Stock Levels
                </label>
                <label>
                    <input
                        type="radio"
                        value="profitLoss"
                        checked={reportType === 'profitLoss'}
                        onChange={(e) => setReportType(e.target.value)}
                    />
                    Profit/Loss
                </label>
            </div>
            <div className="date-range">
                <label>
                    Start Date :
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </label>
                <label>
                    End Date :
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleGenerateReport} disabled={loading}>
                {loading ? 'Generating...' : 'Generate Report'}
            </button>

            {formError && <div className="error">{formError}</div>}
            {error && <div className="error">{error}</div>}

            {renderReport()}
        </div>
    );
};

export default Reports;
