import React, { useEffect, useState } from 'react';
import Dashboard from '../components/Dashboard';
import SalesChart from '../components/SalesChart';
import TopProducts from '../components/TopProducts';
import { getAllProducts } from '../api/productApi';
import '../assets/styles/DashboardPage.css';
import TopSellingProducts from '../components/TopSellingProducts';
import StaffDashboard from '../components/StaffDashboard';

const DashboardPage = () => {
    const [totalCategories, setTotalCategories] = useState(0);
    const [lowStockCount, setLowStockCount] = useState(0);
    const [salesData, setSalesData] = useState([]);

    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const fetchData = async () => {
            const products = await getAllProducts();

            // Unique categories
            const categories = new Set(products.map(product => product.category));
            setTotalCategories(categories.size);

            // Low stock count
            const lowStock = products.filter(product => product.stock < 6);
            setLowStockCount(lowStock.length);

            // Top-selling products based on sales
            const sortedProducts = [...products]
                .sort((a, b) => b.sales - a.sales)
                .slice(0, 10); // Get top 10 products for chart

            // Prepare sales data for chart
            const salesDataArray = sortedProducts.map(product => ({
                name: product.name,
                totalSales: product.sales,
            }));
            setSalesData(salesDataArray);
        };

        fetchData();
    }, []);


    return (
        <>
            <div className="dashboard-page">
                {/* Render both Dashboard and SalesChart if the user is an admin */}
                {userRole === 'admin' && (
                    <Dashboard
                        totalCategories={totalCategories}
                        lowStockCount={lowStockCount}
                    />

                )}

                {userRole === 'staff' && (
                    <StaffDashboard
                        totalCategories={totalCategories}
                        lowStockCount={lowStockCount}
                    />
                )}

                <hr />
                <TopSellingProducts />

                {userRole === 'admin' && (
                    <SalesChart salesData={salesData} />
                )}

                <hr />
                <TopProducts />

            </div>
        </>
    );
};

export default DashboardPage;
