import axios from 'axios';

const API_URL = 'https://api.nbeer.shop/user';

// Helper to get the token from localStorage
const getAuthToken = () => {
    return localStorage.getItem('token'); // Get the JWT token from localStorage
};


// Fetch all users
export const getUsers = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

// Create a new user
export const createUser = async (user) => {
    const response = await axios.post(`${API_URL}/create`, user);
    return response.data;
};

// Fetch a single user by ID
export const getUserById = async (userId) => {
    const response = await axios.get(`${API_URL}/get/${userId}`);
    return response.data;
};

// Fetch roles (Example: for authorization or permissions)
export const fetchRoles = async () => {
    const response = await axios.get(`${API_URL}/roles`);
    return response.data;
};

// Update a user by ID
export const updateUser = async (userId, updatedData) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('Authentication token is missing');
    }
    const response = await axios.put(`${API_URL}/update/${userId}`, updatedData, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data;
};

// Delete a user by ID
export const deleteUser = async (userId) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('Authentication token is missing');
    }
    const response = await axios.delete(`${API_URL}/del/${userId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data;
};

// Login user
export const loginUser = async (credentials) => {
    const response = await axios.post(`${API_URL}/login`, credentials);
    return response.data;
};

// Forgot password (POST request to send reset link to the user's email)
export const forgotPassword = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/forgot-password`, { email });
        return response.data;
    } catch (error) {
        console.error("Error in forgotPassword API:", error);
        throw error; // You can handle the error differently if needed (e.g., showing an error message)
    }
};

// Reset password (PUT request to change the password after receiving reset token)
export const resetPassword = async (token, newPassword) => {
    const response = await axios.put(`${API_URL}/reset-password`, {
        resetToken: token,  // Send the token from URL
        newPassword,        // Send the new password
    });
    return response.data;  // Return the response from backend (success/error message)
};


// Get Total Users
export const getTotalUsers = async () => {
    const response = await axios.get(`${API_URL}/totalUsers`);
    return response.data
}