import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../utils/formatPrice';
import { getTotalSales, getTotalOrdersByQuantity } from '../api/orderApi.js';
import { getTotalProducts, getLowStockCount, getTotalOrderCount } from '../api/productApi.js';
import FilterDropdown from './FilterDropdown'; // Import your dropdown component
import companylogo from '../assets/images/our_logo.png'

const StaffDashboard = ({ totalCategories }) => {
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [totalOrderCount, setTotalOrderCount] = useState(0);
    const [lowStockCount, setLowStockCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsResponse = await getTotalProducts();
                setTotalProducts(productsResponse.totalProducts);

                const salesResponse = await getTotalSales();
                setTotalSales(salesResponse.totalSales);

                const orderCountResponse = await getTotalOrderCount();
                setTotalOrderCount(orderCountResponse.totalOrderCount);

                const lowStockCountResponse = await getLowStockCount();
                setLowStockCount(lowStockCountResponse.lowStockCount);
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        };

        fetchData();
    }, []);

    const navigateTo = (path) => {
        navigate(path);
    };

    const handleFilterSelect = (filter) => {
        if (filter === 'custom') {
            // Navigate to the custom filter page, use a different route if needed
            navigate(`/filtered-orders/custom`);
        } else {
            // For predefined filters
            navigate(`/filtered-orders/${filter}`);
        }
    };

    return (
        <div className="dashboard">
            <div className='head'>
                <h2>Dashboard Overview</h2>
                <img src={companylogo} className='company_logo' />
            </div>
            <div className="dashboard-cards">
                <div className='grid products'>
                    <div className="card" onClick={() => navigateTo('/productlist')}>
                        <h3>Total Products</h3>
                        <p>{totalProducts}</p>
                    </div>
                </div>
                <div className='grid categories'>
                    <div className="card">
                        <h3>Total Categories</h3>
                        <p>{totalCategories}</p>
                    </div>
                </div>
                <div className='grid sales'>
                    <div className="card">
                        <h3>Total Sales</h3>
                        <p>{formatPrice(totalSales)}</p>
                    </div>
                </div>
                <div className='grid low-stock'>
                    <div className="card" onClick={() => navigateTo('/lowstockproducts')}>
                        <h3>Low Stock Products</h3>
                        <p>{lowStockCount}</p> {/* Display the low stock count here */}
                    </div>
                </div>
                <div className='grid order-count'>
                    <div className="card" onClick={() => navigateTo('/orders')}>
                        <h3>Total Orders</h3>
                        <p>{totalOrderCount}</p>
                    </div>
                    <div className='filter'>
                        <FilterDropdown onFilterSelect={handleFilterSelect} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffDashboard;
