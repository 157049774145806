import React, { useState } from 'react';
import { createOrder } from '../api/orderApi';
import success from '../assets/images/tick.png';
import oops from '../assets/images/oops.png';

const OrderSubmit = ({ cart, totalPrice, totalQuantity, setCart, orderSubmitted }) => {
    // State to manage the message
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');  // 'success' or 'error'

    const handleSubmitOrder = async () => {
        // Check if cart is empty
        if (cart.length === 0) {
            setMessage("Your cart is empty. Please add items to the cart before placing the order.");
            setMessageType('error');
            setTimeout(() => {
                setMessage('');  // Clear the message after 3 seconds
            }, 3000);
            return; // Exit the function if the cart is empty
        }

        // Map cart items to match the backend expected format (productID and quantity)
        const products = cart.map(item => ({
            productID: item._id, // Assuming each item in cart has a _id field for the product ID
            quantity: item.quantity,
        }));

        const orderData = {
            products,
            totalPrice,
            totalQuantity,
        };

        try {
            const response = await createOrder(orderData);
            if (response.success) {
                setMessage("Order placed successfully!");
                setMessageType('success');
                setCart([]); // Clear the cart after successful order
                setTimeout(() => {
                    setMessage(''); // Clear the message after 2 seconds
                    window.location.reload(); // Reload the page after the message disappears
                }, 2000);// Reload the page after success
            } else {
                setMessage("Failed to place order.");
                setMessageType('error');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            }
        } catch (error) {
            console.error("Error creating order:", error);
            setMessage("An error occurred. Please try again later.");
            setMessageType('error');
            setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    };

    return (
        <div className='order-submit'>
            <div className='payment-btn'>
                <button onClick={handleSubmitOrder} className='payment-done'>
                    Payment Done
                </button>
            </div>
            {/* Show the message if it exists */}
            {message && (
                <div className={`message ${messageType} ${message ? 'show' : ''}`}>
                    {/* Show the success icon if messageType is 'success' */}
                    {messageType === 'success' && <img src={success} alt="Success" className="success-icon" />}
                    {/* Show the oops icon if messageType is 'error' */}
                    {messageType === 'error' && <img src={oops} alt="Oops" className="error-icon" />}
                    {message}
                </div>
            )}
        </div>
    );
};

export default OrderSubmit;
