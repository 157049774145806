import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../api/userApi';  // Ensure this is correctly importing
import '../assets/styles/Login.css';

const Login = () => {
  // State variables for managing form input and error messages
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  // State for error message
  const navigate = useNavigate();  // For programmatically navigating the user

  // Handle login submission
  const handleLogin = async (e) => {
    e.preventDefault();  // Prevent default form submission

    setErrorMessage(''); // Clear previous error message before trying again

    try {
      // Sending login request to backend via the loginUser function
      const response = await loginUser({ email, password });

      // Store the token and user data in localStorage
      localStorage.setItem('token', response.token);
      localStorage.setItem('userId', response.userId);
      localStorage.setItem('userName', response.name);
      localStorage.setItem('userEmail', response.email);
      localStorage.setItem('userRole', response.role);  // Store user role

      // Redirect based on user role
      if (response.role === 'admin') {
        navigate('/dashboard');  // Redirect to admin dashboard
      } else if (response.role === 'staff') {
        navigate('/dashboard');  // Redirect to staff dashboard
      } else {
        navigate('/');  // Default page or redirect
      }

    } catch (error) {
      // Handle error if login fails
      setErrorMessage(error.response?.data?.message || "Login failed. Please try again.");
      console.error("Login failed:", error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Welcome Back</h2>
        <p>Enter your credentials to login</p>
        <form onSubmit={handleLogin}>
          {/* Email Input */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}  // Update email state
              required
            />
          </div>

          {/* Password Input */}
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}  // Toggle password visibility
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}  // Update password state
              required
            />
            {/* Show password checkbox */}
            <p className='show_password'>
              <input
                type='checkbox'
                id="check"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}  // Toggle show/hide password
              />
              <label htmlFor='check'>Show Password</label>
            </p>
          </div>

          {/* Submit Button */}
          <button type="submit" className="login-button">Login</button>
        </form>

        {/* Display error message if login fails */}
        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}

        {/* Additional Links */}
        <div className="login-links">
          <Link to="/forgot-password">Forgot password?</Link>
          <p>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
