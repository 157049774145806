import axios from 'axios';

const API_URL = 'https://api.nbeer.shop/products';

// Helper to get the token from localStorage
const getAuthToken = () => {
    return localStorage.getItem('token'); // Get the JWT token from localStorage
};

// Fetch all products
export const getAllProducts = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

// Get product by id
export const getProductById = async (productID) => {
    const response = await axios.get(`${API_URL}/get/${productID}`);
    return response.data;
};

// Fetch category (Example: for authorization or permissions)
export const fetchCategory = async () => {
    const response = await axios.get(`${API_URL}/category`);
    return response.data;
};

// Fetch roles (Example: for authorization or permissions)
export const fetchTypes = async () => {
    const response = await axios.get(`${API_URL}/types`);
    return response.data;
};

// Fetch sizes (Example: for authorization or permissions)
export const fetchSizes = async () => {
    const response = await axios.get(`${API_URL}/sizes`);
    return response.data;
};

// Add a new product
export const addProduct = async (product) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('Authentication token is missing');
    }
    const response = await axios.post(`${API_URL}/add`, product, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data;
};

// Update product by barcode
export const updateProduct = async (barcode, updatedData) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('Authentication token is missing');
    }
    const response = await axios.put(`${API_URL}/update/${barcode}`, updatedData ,{
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data;
};

// Delete product by barcode
export const deleteProduct = async (barcode) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('Authentication token is missing');
    }
    const response = await axios.delete(`${API_URL}/del/${barcode}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data;
};

// Get product by barcode
export const getProduct = async (barcode) => {
    const response = await axios.get(`${API_URL}/barcode/${barcode}`);
    return response.data;
};

// Get recent products
export const getAllRecentProducts = async () => {
    const response = await axios.get(`${API_URL}/recent`);
    return response.data;
};

// Get total order count product
export const getTotalOrderCount = async () => {
    const response = await axios.get(`${API_URL}/count`);
    return response.data;
};

// Get total sales for product
export const getTotalSales = async () => {
    const response = await axios.get(`${API_URL}/sales`);
    return response.data;
};

// Get total products
export const getTotalProducts = async () => {
    const response = await axios.get(`${API_URL}/totalproducts`);
    return response.data;
};

// Get low stock products (stock <= 40)
export const getLowStockProducts = async () => {
    const response = await axios.get(`${API_URL}/lowstock`);
    return response.data;
};

// Get count of low stock products (stock <= 40)
export const getLowStockCount = async () => {
    const response = await axios.get(`${API_URL}/lowstock/count`);
    return response.data;
};
