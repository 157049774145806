import axios from 'axios';

// Set the base API URL (you can configure this in a separate config file if preferred)
const API_URL = 'https://api.nbeer.shop/reports'; // Your backend URL

// Helper to get the token from localStorage
const getAuthToken = () => {
    return localStorage.getItem('token'); // Get the JWT token from localStorage
};

// Function to get the report data
export const getReport = async (reportType, startDate, endDate) => {
    const token = getAuthToken()
    
    if (!token) {
        throw new Error('You must be logged in to view reports');
    }

    try {
        const response = await axios.get(`${API_URL}/generate-report`, {
            params: {
                reportType,
                startDate,
                endDate,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Return the fetched data
    } catch (error) {
        // Log the error and throw a more informative message
        console.error('Error fetching the report:', error);
        throw new Error(error.response?.data?.message || 'Error fetching the report');
    }
};
