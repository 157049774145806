import React, { useState, useEffect } from 'react';
import '../assets/styles/BarcodeScanner.css'

const BarcodeScanner = ({ onScan }) => {
  const [barcode, setBarcode] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the key pressed is Enter and there's a barcode value
      if (event.key === 'Enter' && barcode) {
        onScan(barcode);  // Trigger the scan action in the parent (PurchaseManager)
        setBarcode('');  // Clear barcode after scanning
      } else if (event.key !== 'Enter') {
        setBarcode((prev) => prev + event.key);  // Accumulate the scanned barcode characters
      }
    };

    // Add event listener for keydown (e.g., scanner "typing")
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcode, onScan]);

  return (
    <div className="barcode-scanner">
      <h3>Scan a Product Barcode</h3>
      <input
        type="text"
        value={barcode}
        readOnly
        placeholder="Scan the barcode"
        autoFocus
      />
    </div>
  );
};

export default BarcodeScanner;
