import React, { useState, useEffect } from 'react';
import '../assets/styles/EditProduct.css';
import { fetchCategory, fetchSizes, fetchTypes } from '../api/productApi';

const EditProduct = ({ isOpen, onClose, product, onSave }) => {
    const [editedProduct, setEditedProduct] = useState(product);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        setEditedProduct(product);
    }, [product]);

    useEffect(() => {
        const getCategory = async () => {
            try {
                const response = await fetchCategory(); // Fetch category from backend
                setCategories(response.categories); // Set the category in state
            } catch (error) {
                console.error("Error fetching category:", error.message);
            }
        };

        const getTypes = async () => {
            try {
                const response = await fetchTypes(); // Fetch types from backend
                setTypes(response.types); // Set the types in state
            } catch (error) {
                console.error("Error fetching types:", error.message);
            }
        };

        const getSizes = async () => {
            try {
                const response = await fetchSizes(); // Fetch sizes from backend
                setSizes(response.sizes); // Set the types in state
            } catch (error) {
                console.error("Error fetching sizes:", error.message);
            }
        };

        getCategory();
        getTypes();
        getSizes();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(editedProduct);
        onClose(); // Close the modal after saving
    };

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>Edit Product</h2>
                    <form onSubmit={handleSubmit}>
                        <label>Product Name</label>
                        <input
                            type="text"
                            value={editedProduct.name}
                            onChange={(e) => setEditedProduct({ ...editedProduct, name: e.target.value })}
                            placeholder="Name"
                        />
                        <label>Brand</label>
                        <input
                            type="text"
                            value={editedProduct.brand}
                            onChange={(e) => setEditedProduct({ ...editedProduct, brand: e.target.value })}
                            placeholder="Brand"
                        />
                        <label>Barcode</label>
                        <input
                            type="text"
                            value={editedProduct.barcode}
                            readOnly
                        />
                        <div className='two'>
                            <div className='form-group'>
                                <label>Category</label>
                                <select
                                    value={editedProduct.category}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, category: e.target.value })} required>
                                    {categories.map((categoryOption) => (
                                        <option key={categoryOption} value={categoryOption}>
                                            {categoryOption.charAt(0).toUpperCase() + categoryOption.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Type</label>
                                <select
                                    value={editedProduct.type}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, type: e.target.value })} required>
                                    {types.map((typeOption) => (
                                        <option key={typeOption} value={typeOption}>
                                            {typeOption.charAt(0).toUpperCase() + typeOption.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='two'>
                            <div className='form-group'>
                                <label>Cost Price</label>
                                <input
                                    type="number"
                                    value={editedProduct.cp}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, cp: Number(e.target.value) })}
                                    placeholder="Cost Price"
                                />
                            </div>
                            <div className='form-group'>
                                <label>Selling Price</label>
                                <input
                                    type="number"
                                    value={editedProduct.sp}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, sp: Number(e.target.value) })}
                                    placeholder="Selling Price"
                                />
                            </div>
                        </div>
                        <div className='two'>
                            <div className='form-group'>
                                <label>Size</label>
                                <select
                                    value={editedProduct.size}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, size: e.target.value })} required>
                                    {sizes.map((sizeOption) => (
                                        <option key={sizeOption} value={sizeOption}>
                                            {sizeOption.charAt(0).toUpperCase() + sizeOption.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Stock</label>
                                <input
                                    type="number"
                                    value={editedProduct.stock}
                                    onChange={(e) => setEditedProduct({ ...editedProduct, stock: Number(e.target.value) })}
                                    placeholder="Stock"
                                />
                            </div>
                        </div>
                        <div className='btns'>
                            <button type="submit">Save</button>
                            <button type="button" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
};

export default EditProduct;
