import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/styles/Sidebar.css';
import dashboard from '../assets/images/dashboard.png'
import users from '../assets/images/user.png'
import list from '../assets/images/list.png'
import add from '../assets/images/add.png'
import low from '../assets/images/lowstock.png'
import billing from '../assets/images/billing.png'
import orders from '../assets/images/orders.png'
import arrow from '../assets/images/arrow.png'
import allorders from '../assets/images/allorders.png'
import history from '../assets/images/history.png'
// import reports from '../assets/images/reports.png'


const Sidebar = () => {
    const location = useLocation();
    const [isOrdersOpen, setIsOrdersOpen] = useState(false); // Dropdown state
    const toggleOrdersDropdown = () => setIsOrdersOpen(!isOrdersOpen); // Toggle function

    // Retrieve the user role from localStorage (assuming it's stored during login)
    const userRole = localStorage.getItem('userRole'); // 'admin' or 'staff'

    return (
        <div className="sidebar">
            <ul>
                {/* Dashboard link */}
                <li>
                    <Link to="/dashboard" className={location.pathname === '/dashboard' ? 'active-link' : ''}>
                        <img src={dashboard} /> Dashboard
                    </Link>
                </li>

                {/* Show Users link only for admin */}
                {userRole === 'admin' && (
                    <li>
                        <Link to="/userlist" className={location.pathname === '/userlist' ? 'active-link' : ''}>
                            <img src={users} /> Users
                        </Link>
                    </li>
                )}

                {/* Show Product List link for both admin and staff */}
                <li>
                    <Link to="/productlist" className={location.pathname === '/productlist' ? 'active-link' : ''}>
                        <img src={list} /> Product List
                    </Link>
                </li>

                {/* Show Add Product link only for admin */}
                {userRole === 'admin' && (
                    <li>
                        <Link to="/add-product" className={location.pathname === '/add-product' ? 'active-link' : ''}>
                            <img src={add} /> Add New Product
                        </Link>
                    </li>
                )}

                {/* Show Low Stock Products link for both admin and staff */}
                <li>
                    <Link to="/lowstockproducts" className={location.pathname === '/lowstockproducts' ? 'active-link' : ''}>
                        <img src={low} /> Low Stock Products
                    </Link>
                </li>


                <li>
                    <Link to="/billing" className={location.pathname === '/billing' ? 'active-link' : ''}>
                        <img src={billing} /> Billing
                    </Link>
                </li>

                {/* Orders Dropdown (visible for both admin and staff) */}
                <li>
                    <div className="dropdown" onClick={toggleOrdersDropdown}>
                        <Link to="#" className={location.pathname.startsWith('/order') ? 'active-link' : ''}>
                            <img src={orders} /> Orders
                            <img src={arrow} className={`arrow-icon ${isOrdersOpen ? 'rotate' : ''}`} />
                        </Link>
                    </div>
                    {isOrdersOpen && (
                        <ul className="dropdown-menu">
                                <li>
                                    <Link to="/orders" className={location.pathname === '/orders' ? 'active-link' : ''}>
                                        <img src={allorders} /> All Orders
                                    </Link>
                                </li>
                            {/* <li>
                                <Link to="/orderhistory" className={location.pathname === '/orderhistory' ? 'active-link' : ''}>
                                    <img src={history} /> Order History
                                </Link>
                            </li> */}
                        </ul>
                    )}
                </li>

                {/* Show Add Product link only for admin */}
                {/* {userRole === 'admin' && (
                    <li>
                        <Link to="/reports" className={location.pathname === '/reports' ? 'active-link' : ''}>
                            <img src={reports} /> Reports
                        </Link>
                    </li>
                )} */}
            </ul>
        </div>
    );
};

export default Sidebar;
